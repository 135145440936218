import React, { ReactElement, ReactNode, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import classnames from 'classnames';
import './style.css';
import { isBoolean, isFunction, noop } from 'lodash';
import { getFunVarResult } from 'component_utils/utils';
import { ScannerFocusTrigger } from 'components/Scanner';

type IProps = {
  id?: string;
  open?: boolean;
  fullscreen?: boolean;
  disableCloseTriggers?: boolean;
  title: ReactNode;
  buttons?: ReactElement[] | ((close: () => void) => ReactElement[]);
  trigger?: (open: () => void) => ReactNode;
  onKeyListener?: (e: KeyboardEvent) => boolean;
  onClose?: () => Promise<void> | void;
  onOpen?: () => Promise<void> | void;
  onOpened?: () => Promise<void> | void;
  doNotPreventDefault?: boolean;
  children?: ReactNode | ((close: () => void) => ReactNode);
};

export interface ModalRef {
  toggle: () => void;
  toggleClose: () => void;
  toggleOpen: () => void;
}

export default React.forwardRef<ModalRef, IProps>(
  (
    {
      id,
      open,
      fullscreen,
      disableCloseTriggers,
      title,
      doNotPreventDefault,
      trigger,
      onKeyListener,
      onClose,
      onOpen,
      onOpened,
      buttons = [],
      children,
    },
    ref,
  ) => {
    const [_open, __setOpen] = useState(false);
    const resolvedOpen = isBoolean(open) ? open : _open;

    const _setOpen = useCallback(
      (fun: ((old: boolean) => boolean) | boolean) => {
        const newValue = getFunVarResult(fun, resolvedOpen);
        if (resolvedOpen === newValue) {
          return;
        }

        // call state change handlers if required
        if (newValue) {
          if (onOpen) onOpen();
        } else {
          if (onClose) onClose();
        }
        __setOpen(newValue);
      },
      [__setOpen, resolvedOpen, onClose, onOpen],
    );

    const toggle = useCallback(() => _setOpen((old) => !old), [_setOpen]);
    const toggleClose = useCallback(() => _setOpen(false), [_setOpen]);
    const toggleOpen = useCallback(() => _setOpen(true), [_setOpen]);
    const resolvedButtons = isFunction(buttons) ? buttons(toggleClose) : buttons;
    const resolvedChildren = isFunction(children) ? children(toggleClose) : children;

    useImperativeHandle(
      ref,
      () => ({
        toggle,
        toggleClose,
        toggleOpen,
      }),
      [toggle, toggleOpen, toggleClose],
    );

    useEffect(() => {
      if (!onKeyListener) {
        return;
      }

      // register a key listener
      const onKeyDown = (e: KeyboardEvent) => {
        if (onKeyListener(e)) {
          toggle();
        }
      };
      document.addEventListener('keydown', onKeyDown);
      return () => document.removeEventListener('keydown', onKeyDown);
    }, [onKeyListener, toggle]);

    return (
      <>
        {trigger && trigger(toggleOpen)}
        <Modal
          id={id}
          onClick={(e) => {
            e.stopPropagation();
          }}
          onMouseDown={!doNotPreventDefault ? (e) => {
            e.stopPropagation();
          } : undefined}
          onMouseMove={!doNotPreventDefault ? (e) => {
            e.stopPropagation();
          } : undefined}
          isOpen={resolvedOpen}
          className={classnames({
            'custom-modal custom-modal-full-width flex-container': fullscreen,
          })}
          onOpened={onOpened || noop}
          toggle={!disableCloseTriggers ? toggleClose : undefined}
        >
          <ScannerFocusTrigger/>
          <ModalHeader toggle={!disableCloseTriggers ? toggleClose : undefined}>{title}</ModalHeader>

          <ModalBody
            className={classnames({
              'flex-fill-height custom-modal-body': fullscreen,
            })}
          >
            {resolvedChildren}
          </ModalBody>

          {buttons.length > 0 && (
            <ModalFooter>
              {resolvedButtons.map((b, index) => React.cloneElement(b, { key: index }))}
            </ModalFooter>
          )}
        </Modal>
      </>
    );
  },
);
