import React, { useState, useEffect, useContext, ReactNode, useMemo, useCallback } from 'react';
import get from 'lodash/get';
import set from 'lodash/set';
import isString from 'lodash/isString';
import { getFunVarResult, HooksHoc, useStable } from 'component_utils/utils';
import { Function1, isArray, merge, unset } from 'lodash';
import { useConfig } from './Config';
import Raw from 'components/Raw';

declare global {
  interface Window {
    gT: TTranslate,
    gtJSX: TJSXTranslate,
  }
}


export type TTranslate = (key: string, params?: { [x: string]: string | number }) => string;
export type TJSXTranslate = (key: string, params?: { [x: string]: ReactNode }) => ReactNode;

export interface ICurrentTranslationKey {
  key: string;
  args: string[];
}

export interface ILanguageContext {
  setLanguage: any;
  T: TTranslate;
  TJSX: TJSXTranslate;
}

export interface ITranslationEditorContext {
  canEditTranslations: boolean;
  setCanEditTranslations: (v: boolean | ((v: boolean) => boolean)) => void;

  translationKey: ICurrentTranslationKey;
  setTranslationKey: (k: ICurrentTranslationKey) => void;

  languages: string[];

  loadAllLanguages: () => Promise<typeof cachedLanguages>;
  updateLanguageKey: (language: string, path: string, newValue: string) => void;
}

export const LanguageContext = React.createContext<ILanguageContext>({} as ILanguageContext);
export const TranslationEditorContext = React.createContext<ITranslationEditorContext>({} as ITranslationEditorContext);
export const baseLanguageFileCache: any = {};
export const specializationLanguageFileCache: any = {};
export const patchLanguageFileCache: any = {};
export const cachedLanguages: any = {};

export const TRANSLATE = 'T.';
export const DEFAULT_LANGUAGE = 'English';

export const Tests = {
  LanguageContext,
};

export function useTranslations() {
  const ctx = useContext(LanguageContext);
  return ctx.T;
}

export function useJsxTranslations() {
  const ctx = useContext(LanguageContext);
  return ctx.TJSX;
}

export function useSetLanguage() {
  return useContext(LanguageContext).setLanguage;
}

export function useEditableTranslations(): [boolean, ITranslationEditorContext['setCanEditTranslations']] {
  const ctx = useContext(TranslationEditorContext);
  return [ctx.canEditTranslations, ctx.setCanEditTranslations];
}

export function useTranslationEditorContext() {
  return useContext(TranslationEditorContext);
}

export function withLanguage<P extends object>(C: React.ComponentType<P>) {
  return HooksHoc(C, () => {
    const ctx = useContext(LanguageContext);
    return {
      language: ctx,
      T: ctx.T,
      TJSX: ctx.TJSX,
    };
  });
}

const tryFetchOrEmpty = async (file: string) => {
  const fetchResult = await fetch(file);
  if (fetchResult.status !== 200) {
    return {};
  }
  const json = await fetchResult.json();
  return json;
};

const languageWaiters: { [k: string]: Function1<any, void>[] } = {};
const loadLanguage = async (customerId: string, language: string, languageFileName: string) => {
  if (Object.keys(cachedLanguages).includes(language)) {
    console.log('Language is already loaded', language);
    return;
  }

  // if a process is already loading the languages we wait for it to finish
  if (isArray(languageWaiters[language])) {
    console.log('Waiting for other process to finish loading language', language);
    await new Promise((resolve) => languageWaiters[language].push(resolve));
    return;
  }
  languageWaiters[language] = [];

  // load the actual language
  console.log('Loading language', language);
  const languageFile = (baseLanguageFileCache[language] = await tryFetchOrEmpty('/translations/' + languageFileName));
  const specializationLanguageFile = (specializationLanguageFileCache[language] = await tryFetchOrEmpty(
    '/translations/specialization/' + customerId + "/" + languageFileName,
  ));
  const patchLanguageFile = (patchLanguageFileCache[language] = await tryFetchOrEmpty(
    '/api/v1/translations/patchFile/' + language,
  ));

  const merged = merge({}, languageFile, specializationLanguageFile, patchLanguageFile);
  cachedLanguages[language] = merged;

  // notify all waiting processes that the language has been loaded
  languageWaiters[language].forEach((resolve) => resolve(true));
  delete languageWaiters[language];
};

export const LanguageProvider = (props: any) => {
  const config = useConfig();
  const customerId = config.customerId;
  const [languageFiles] = useStable(config.languageFiles);
  const [language, setStateLanguage] = useState<string | null>(null);
  const [allowEditingTranslations, setAllowEditingTranslations] = useState<boolean>(false);
  const [selectedTranslationKey, setSelectedTranslationKey] = useState<ICurrentTranslationKey>(null);
  const [triggerLanguageRerender, setTriggerLanguageRerender] = useState(0);

  const setLanguage = useCallback(
    async (language: string) => {
      let newLan = language;
      if (!Object.keys(languageFiles).includes(language)) {
        newLan = DEFAULT_LANGUAGE;
      }
      const languageFileName = languageFiles[newLan];
      await loadLanguage(customerId, newLan, languageFileName);
      setStateLanguage(newLan);
    },
    [customerId, setStateLanguage, languageFiles],
  );

  const T = useCallback(
    (text: string, params: any = {}, jsx = false): string | ReactNode => {
      if (!text) {
        return '';
      }

      if (!text.startsWith(TRANSLATE)) {
        return text;
      }

      if (!Object.keys(cachedLanguages).includes(language!)) {
        return '';
      }

      const key = text.substring(TRANSLATE.length);
      const template = get(cachedLanguages[language!], key);
      if (!template || !isString(template)) {
        return `err: ${text}`;
      }

      // should we do a replace with components?
      const templateParts = template.split(new RegExp('(\\{.*?\\})', 'gi'));
      const formatted = templateParts.map((str) => {
        if (str.charAt(0) === '{' && str.charAt(str.length - 1) === '}') {
          const key = str.substring(1, str.length - 1);
          if (key === 'newline') {
            return <br />;
          }
          return params[key];
        }
        return str;
      });

      if (jsx) {
        return (
          <>
            {formatted.map((item, index) => (
              isString(item) ? (
                <Raw key={index}>{item}</Raw>
              ) : (
                <React.Fragment key={index}>{item}</React.Fragment>
              )
            ))}
          </>
        );
      }
      return formatted.join('');
    },
    [language],
  );

  useEffect(() => {
    setLanguage(DEFAULT_LANGUAGE);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const ctx = useMemo<ILanguageContext>(() => {
    const editableJsx: TJSXTranslate = (key, params) => {
      const inner = T(key, params, true) as ReactNode;
      if (!key.startsWith(TRANSLATE)) {
        return inner;
      }
      return (
        <span
          className="language_object"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setSelectedTranslationKey({
              key: key.substring(TRANSLATE.length),
              args: params ? Object.keys(params) : [],
            });
          }}
        >
          {inner}
        </span>
      );
    };

    return {
      triggerLanguageRerender,
      setLanguage: setLanguage,
      T: (key, params) => T(key, params, false) as string,
      TJSX: allowEditingTranslations ? editableJsx : (key, params) => T(key, params, true) as ReactNode,
    };
  }, [setLanguage, T, allowEditingTranslations, triggerLanguageRerender]);

  window.gT = ctx.T
  window.gtJSX = ctx.TJSX

  const translationEditorContext = useMemo(() => {
    const loadAllLanguages = async () => {
      await Promise.all(
        Object.keys(languageFiles).map(async (l) => {
          await loadLanguage(customerId, l, languageFiles[l]);
        }),
      );
      return cachedLanguages;
    };

    const _setAllowEditingTranslations = async (v: boolean | ((v: boolean) => boolean)) => {
      const newState = getFunVarResult(v, allowEditingTranslations);
      if (newState) {
        await loadAllLanguages();
      }
      setAllowEditingTranslations(newState);
    };

    return {
      canEditTranslations: allowEditingTranslations,
      setCanEditTranslations: _setAllowEditingTranslations,

      translationKey: selectedTranslationKey,
      setTranslationKey: setSelectedTranslationKey,

      languages: Object.keys(languageFiles),

      triggerLanguageRerender,
      loadAllLanguages,
      updateLanguageKey: (language: string, path: string, newValue: string) => {
        if (newValue !== null) {
          set(patchLanguageFileCache[language], path, newValue);
          set(cachedLanguages[language], path, newValue);
        } else {
          unset(patchLanguageFileCache[language], path);
          set(
            cachedLanguages[language],
            path,
            get(
              patchLanguageFileCache[language],
              path,
              get(specializationLanguageFileCache[language], path, get(baseLanguageFileCache[language], path, '')),
            ),
          );
        }
        setTriggerLanguageRerender((old) => old + 1);
      },
    };
  }, [
    languageFiles,
    selectedTranslationKey,
    allowEditingTranslations,
    triggerLanguageRerender,
    setSelectedTranslationKey,
    setTriggerLanguageRerender,
    customerId,
  ]);

  return (
    <LanguageContext.Provider value={ctx}>
      <TranslationEditorContext.Provider value={translationEditorContext}>
        {language !== null && props.children}
      </TranslationEditorContext.Provider>
    </LanguageContext.Provider>
  );
};
