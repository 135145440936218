import React from 'react';
import { Tab, UncontrolledTabs } from 'components/uncontrolled_tabs';
import Model from 'components/objects/model';
import PropertyList from 'components/property_list';
import { IBatch } from 'interfaces';
import Translation from 'utils/Language/Translation';
import Date, { DATE } from 'components/Date';
import Bool from 'components/Bool';
import { ButtonGroup } from 'reactstrap';
import ProcessingButton from 'components/ProcessingButton';
import { askPrinterIfNotSet, prompt } from 'utils/Prompts';
import { useApi, useDeviceConfig } from 'utils/API';
import { RiNumber1 } from 'react-icons/ri';
import { FaCopy } from 'react-icons/fa';
import { sleep } from 'component_utils/utils';
import moment from 'moment';
import RoleProtected from 'components/RoleProtected';

interface Props {
  inline?: boolean;
  obj: IBatch | null;
}

const Batch = ({ inline, obj: batch }: Props) => {
  const api = useApi();
  const deviceConfig = useDeviceConfig().deviceConfig

  const printLabels = async (quantity: number) => {
    if (quantity) {
      const item = await api.item.getItem(batch.itemCode)
      await api.item.printLabel(
        await askPrinterIfNotSet(
          api,
          <Translation name="T.prompts.printerPicker.uses.batchLabels" />,
          deviceConfig.defaultItemLabelPrinter,
          'defaultItemLabelPrinter'
        ),
        {
          receiptCode: null,
          picklistCode: null,
          deliveryCode: null,
          jobs: [{
            item,
            batch,
            serial: null,
            quantity
          }]
        }
      );
    }
  }

  const detailedRender = () => {
    return (
      <UncontrolledTabs key={batch.visualCode}>
        <Tab name={<Translation name="T.misc.information" />}>
          <PropertyList
            rows={[
              [
                <b><Translation name="T.objects.batch.code" /></b>,
                batch.code,
              ],
              [
                <b><Translation name="T.objects.batch.batchCode" /></b>,
                <>
                  {batch.visualCode}
                  {' '}
                  <RoleProtected roles={['authCopyObjectCodes']}>
                    <ProcessingButton color="link" className='p-0' onClick={async () => {
                      navigator.clipboard.writeText(batch.visualCode)
                      await sleep(100)
                    }}>
                      <FaCopy/>
                    </ProcessingButton>
                  </RoleProtected>
                </>,
              ],
              [
                <b><Translation name="T.objects.batch.mnfCode" /></b>,
                batch.mnfBatchCode,
              ],
              [
                <b>
                  <Translation name="T.objects.batch.admissionDate" />
                </b>,
                <Date val={batch.admissionDate} format={DATE} />,
              ],
              [
                <b>
                  <Translation name="T.objects.batch.manufacturingDate" />
                </b>,
                <Date val={batch.manufacturingDate} format={DATE} />,
              ],
              [
                <b><Translation name="T.objects.batch.expirationDate" /></b>,
                <Date val={batch.expirationDate} format={DATE} />,
              ],
              [
                <b><Translation name="T.objects.batch.qualityControlApprovedOrNotRequired" /></b>,
                <Bool val={batch.qualityControlApprovedOrNotRequired} type='YN' />,
              ],
            ]}
          />
        </Tab>
        <Tab
          name={<Translation name="T.misc.actions" />}
        >
          <ButtonGroup className="w-100">
            <ProcessingButton
              block
              color="primary"
              onClick={async () => await printLabels(
                parseInt(await prompt(<Translation name="T.misc.howManyLabels" />, ``))
              )}
            >
              <Translation name="T.misc.printLabels" />
            </ProcessingButton>
            <ProcessingButton
              color="info"
              onClick={async () => await printLabels(1)}
            >
              <RiNumber1/>
            </ProcessingButton>
          </ButtonGroup>
        </Tab>
      </UncontrolledTabs>
    );
  };

  const dialog = {
    titleText: <Translation name="T.objects.batch.batchDetails" />,
    closeBtnText: <Translation name="T.misc.close" />,
    content: () => <Batch obj={batch} />,
  };
  return (
    <Model
      inline={inline}
      hasLoaded={batch}
      description={() => {
        const t = <Translation name='T.objects.batch.shortDescription' params={(batch as any) || {}}/>
        if (batch?.expirationDate) {
          if (moment(batch.expirationDate).isBefore(moment())) {
            return <span className='text-danger'>{t}</span>
          }
        }
        return t
      }}
      copyValue={() => batch.visualCode}
      detailedRender={detailedRender}
      dialog={dialog}
    />
  );
};

export default Batch;
