import SimpleModal, { ModalRef } from 'components/SimpleModal';
import React, { ReactNode, useCallback, useState } from 'react';
import Translation from 'utils/Language/Translation';
import { beTableAsComponent } from 'components/BeTable';
import RDate, { DATE_TIME } from 'components/Date';
import { noop } from 'lodash';
import { FaInfoCircle } from 'react-icons/fa';

interface IBeError {
  path: string;
  type: string;

  at: Date;

  title: ReactNode;
  titleAsString: string;

  msg: ReactNode;
  msgAsString: string;
}

let setErrors: React.Dispatch<React.SetStateAction<IBeError[]>> = noop;
export const AddErrorToLog = (
  path: string,
  type: string,
  title: ReactNode,
  titleAsString: string,
  msg: ReactNode,
  msgAsString: string,
) => {
  setErrors((old) => [
    {
      path,
      type,

      at: new Date(),

      title,
      titleAsString,
      msg,
      msgAsString,
    },
    ...old.slice(0, 250),
  ]);
};

const ErrorTable = beTableAsComponent<IBeError, {}, {}>({
  tableId: 'table#errors',
  getRowClasses: ({ row }) => {
    return [`text-${row.type}`];
  },
  columns: [
    {
      Header: () => <Translation name="T.misc.app" />,
      value: (row) => row.path,
      filterable: true,
    },
    {
      Header: () => <Translation name="T.misc.type" />,
      value: (row) => row.type,
      filterable: true,
      FilterComponent: ({ value, onChange }) => (
        <select value={value} onChange={(e) => onChange(e.target.value)}>
          <option value="" />
          {['success', 'danger', 'info', 'default', 'warning'].map((t) => (
            <option key={t} value={t}>
              {t}
            </option>
          ))}
        </select>
      ),
    },
    {
      Header: () => <Translation name="T.misc.at" />,
      value: (row) => row.at.getTime(),
      Cell: ({ row }) => <RDate val={row.at} format={DATE_TIME} />,
      width: 50,
    },
    {
      Header: () => <Translation name={'T.misc.title'} />,
      Cell: ({ row }) => <>{row.title}</>,
      value: (row) => row.titleAsString,
      fullText: true,
      filterable: true,
    },
    {
      Header: () => <Translation name={'T.misc.message'} />,
      Cell: ({ row }) => <>{row.msg}</>,
      value: (row) => row.msgAsString,
      fullText: true,
      filterable: true,
    },
    {
      Header: '',
      width: 35,
      expander: true,
      expanderAll: true,
      resizable: false,
      fixedWidth: true,
      frozen: true,
      Cell: ({ row }) => {
        return (
          <SimpleModal
            title={row.path}
            trigger={open => <FaInfoCircle onClick={open}/>}
          >
            <span className={`text-${row.type}`}>{row.type}</span><br/>
            <RDate val={row.at} format={DATE_TIME} /><br/>
            <br/>
            <b>{row.title}</b><br/>
            {row.msg}
          </SimpleModal>
        )
      }
    }
  ],
});

export default React.forwardRef<ModalRef, {}>((_, ref) => {
  const [errors, _setErrors] = useState<IBeError[]>([]);
  const keyListener = useCallback((e: KeyboardEvent) => e.shiftKey && e.altKey && e.code === 'KeyE', []);

  setErrors = _setErrors;
  return (
    <SimpleModal 
      ref={ref} 
      fullscreen
      id='error-log-modal'
      title={<Translation name="T.misc.errorHistory" />} 
      onKeyListener={keyListener}
    >
      <div className="flex-container">
        <ErrorTable data={errors} />
      </div>
    </SimpleModal>
  );
});
